import { render, staticRenderFns } from "./DetailsPricesGroup.vue?vue&type=template&id=c772b70c"
import script from "./DetailsPricesGroup.vue?vue&type=script&lang=js"
export * from "./DetailsPricesGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports